// 登录相关接口和其他接口
import request from '@/request/request'

// 登录接口
export const UserLoginApi = (loginVo) => request.post('/frame-web/admin/sys/user/login', loginVo);
// 退出登录接口
export const UserLogoutApi = () => request.post('/frame-web/admin/sys/user/logout');


// 全局数据字典接口
export const DictionaryApi = () => request.get('/frame-web/admin/config/dictionary');
// 获取七牛云token
// export const GetUploadTokenApi = (params) => request.get(`/admin/config/getUploadToken`, { params });

