<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div class="logo" v-show="!collapsed">
        <img src="../assets/images/complete@2x.png" />
        <span>设谷版权登记</span>
      </div>
      <a-menu
        :defaultOpenKeys="['1']"
        :defaultSelectedKeys="['home']"
        :selectedKeys="selectedKeys"
        mode="inline"
        :open-keys="openKeys"
        theme="dark"
        @openChange="onOpenChange"
        @click="menuClick"
      >
        <a-menu-item key="home" @click="() => $router.push('/home')">
          <a-icon type="home" />
          <span>首页</span>
        </a-menu-item>

        <a-sub-menu :key="item.code" v-for="item in powerArr">
          <span slot="title">
            <a-icon :type="item.icon" />
            <span>{{ item.title }}</span>
          </span>
          <a-menu-item :key="e.code" v-for="e in item.children">
            <router-link :to="e.url">{{ e.title }}</router-link>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <!-- 面包屑 -->
        <a-breadcrumb separator=">" class="flex1">
          <a-breadcrumb-item
            v-for="(item, index) of $route.matched"
            :key="index"
          >
            <span style="font-size: 16px" @click="breadcrumbClick(item, index)">
              {{ item.meta.title }}
            </span>
          </a-breadcrumb-item>
        </a-breadcrumb>
        <span class="login_out">
          <div>
            <a-dropdown>
              <span
                class="ant-dropdown-link"
                @click="(e) => e.preventDefault()"
              >
                <img :src="headUrl" alt="头像" />
                {{ userName }}
              </span>
              <a-menu slot="overlay" @click="loginOut">
                <a-menu-item key="1"> 退出登录 </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </span>
      </a-layout-header>
      <!-- 内容区域 -->
      <a-layout-content
        :style="{
          margin: '24px 16px',
          padding: '24px',
          background: '#fff',
          minHeight: '280px',
        }"
      >
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { UserLogoutApi } from "@/request/api/login";
export default {
  created() {
    window.addEventListener("popstate", function () {
      window.location.reload(); //监测history模式url的变化
    });
    this.selectedKeys.push(this.$route.path.split("/")[2]);
    this.openKeys = [];
    this.openKeys.push(this.$route.path.split("/")[1]);
    // 处理侧栏收起状态
    this.$watch("collapsed", () => {
      this.$store.commit("sidebarType", this.collapsed);
    });
    this.userName = localStorage.getItem("userName"); // 设置用户名
    this.headUrl = localStorage.getItem("headUrl"); // 设置头像

    // 权限数组
    this.powerArr = JSON.parse(localStorage.getItem("powerListArr"));
  },
  data() {
    return {
      collapsed: false, // 侧边导航栏是否展开
      userName: "",
      headUrl: "",
      rootSubmenuKeys: [
        "rolePower",
        "systemManage",
        "certificate",
        "crm",
        "operate",
      ],
      selectedKeys: [], // 当前选中的menu-item的key数组
      openKeys: ["rolePower"], // 展开的 sub-menu 的 key 数组
      // 权限数组
      powerArr: [
        {
          code: "rolePower",
          title: "角色和权限",
          icon: "container",
          children: [
            {
              code: "roleManage",
              title: "角色管理",
              url: "/rolePower/roleManage",
            },
            {
              code: "personManage",
              title: "人员管理",
              url: "/rolePower/personManage",
            },
          ],
        },
        {
          code: "certificate",
          title: "证书管理",
          icon: "container",
          children: [
            {
              code: "cunZheng",
              title: "版权存证",
              url: "/certificate/cunZheng",
            },
            {
              code: "dengji",
              title: "版权登记",
              url: "/certificate/dengji",
            },
          ],
        },
        {
          code: "crm",
          title: "用户管理",
          icon: "container",
          children: [
            {
              code: "crmList",
              title: "用户列表",
              url: "/crm/crmList",
            },
            {
              code: "tortList",
              title: "侵权报案",
              url: "/crm/tortList",
            },
          ],
        },
        {
          code: "operate",
          title: "运营管理",
          icon: "container",
          children: [
            {
              code: "bannerList",
              title: "首页轮播图",
              url: "/operate/bannerList",
            },
            {
              code: "newsCenter",
              title: "新闻中心",
              url: "/operate/newsCenter",
            },
            {
              code: "customerservice",
              title: "服务客服",
              url: "/operate/customerservice",
            },
          ],
        },
        {
          code: "systemManage",
          title: "系统管理",
          icon: "solution",
          children: [
            {
              code: "menuManage",
              title: "菜单管理",
              url: "/systemManage/menuManage",
            },
            {
              code: "journalManage",
              title: "操作日志",
              url: "/systemManage/journalManage",
            },
            {
              code: "systemAbnormal",
              title: "异常日志",
              url: "/systemManage/systemAbnormal",
            },
          ],
        },
      ],
    };
  },
  methods: {
    // 面包屑的按钮
    breadcrumbClick(item, index) {
      if (index != 0) {
        this.$router.push(item.path);
      }
    },
    // 切换
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    // SubMenu 展开/关闭的回调
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    menuClick(item) {
      this.selectedKeys = []; // 清空选中的子菜单的key 数组
      this.selectedKeys.push(item.key);
    },
    // 退出登录
    loginOut() {
      UserLogoutApi().then(({ code }) => {
        if (code === 200) {
          localStorage.clear();
          this.$router.push("/login");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
// 退出登录
.login_out {
  margin-right: 24px;
  div {
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .ant-dropdown-trigger {
      cursor: pointer;
    }
  }
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  text-align: center;
}
.ant-layout.ant-layout-has-sider {
  height: 100vh;
}
.ant-layout-header {
  display: flex;
  align-items: center;
}
.ant-layout-content {
  padding: 0 !important;
  position: relative;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  line-height: 32px;
  margin: 16px;
  font-size: 18px;
  text-align: center;
  color: #fff;
  img {
    width: 34px;
    height: 34px;
    margin-right: 5px;
    vertical-align: middle;
  }
  span {
    // color: #ffe1b9;
    font-size: 16px;
    margin-left: 5px;
    font-weight: 500;
  }
}
/deep/.ant-breadcrumb {
  cursor: pointer;
}
// /deep/.ant-dropdown-menu-item:hover,
// .ant-dropdown-menu-submenu-title:hover {
//   background: rgba(255, 225, 185, 0.3);
// }
// /deep/.ant-layout-sider {
//   background-color: #222;
// }
// /deep/.ant-menu-dark,
// .ant-menu-dark .ant-menu-sub {
//   background-color: #222;
// }
// /deep/.ant-menu-dark .ant-menu-item,
// .ant-menu-dark .ant-menu-item-group-title,
// .ant-menu-dark .ant-menu-item > a {
//   color: #fff;
// }
// /deep/.ant-menu-dark .ant-menu-inline.ant-menu-sub {
//   background: rgba(255, 255, 255, 0.1);
//   box-shadow: none;
// }
// /deep/.ant-menu.ant-menu-dark .ant-menu-item-selected,
// .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
//   background: linear-gradient(135deg, #ffe1b9 0%, #e5bb84 100%);
//   color: #222222 !important;
// }
// /deep/.router-link-exact-active {
//   color: #222 !important;
// }
// /deep/.router-link-active {
//   color: #222 !important;
// }
// /deep/.ant-menu-dark .ant-menu-item-selected .anticon + span {
//   color: #222;
// }
// /deep/.ant-menu-dark .ant-menu-item-selected .anticon {
//   color: #222;
// }
// // 收起来的侧边图标
// /deep/.ant-menu-inline-collapsed > .ant-menu-item .anticon,
// .ant-menu-inline-collapsed
//   > .ant-menu-item-group
//   > .ant-menu-item-group-list
//   > .ant-menu-item
//   .anticon,
// .ant-menu-inline-collapsed
//   > .ant-menu-item-group
//   > .ant-menu-item-group-list
//   > .ant-menu-submenu
//   > .ant-menu-submenu-title
//   .anticon,
// .ant-menu-inline-collapsed
//   > .ant-menu-submenu
//   > .ant-menu-submenu-title
//   .anticon {
//   color: #ffe1b9;
// }
// /deep/.anticon[tabindex]:hover {
//   color: #ffe1b9 !important;
// }
</style>
